import './loading.css'

/**
 * @description 创建节点
 * @param{*} removeTime 销毁节点时间 3000/自定义
 */
let node = document.createElement("div");
let childNode = document.createElement("div");
node.setAttribute("class", "loading-custom-boxers");
childNode.setAttribute("class", "loading-custom-js");

// 创建节点
function loadingNode (key) {
  node.appendChild(childNode);
  document.body.appendChild(node);
  if (key) {
    const time = Number(key)
    loadingClear(time)
  }
}
// 消除节点
/**
 *  
 * @param {*} time 时间
 */
function loadingClear (time) {
  let removeTime
  if (typeof (time) == 'number' && time !== 0) {
    removeTime = time
  } else {
    removeTime = 3000
  }
  setTimeout(() => {
    node.style['opacity'] = 0
    setTimeout(() => {
      node.remove()
    }, removeTime)
  }, removeTime)
}
export { loadingNode, loadingClear }