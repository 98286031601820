import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from '@/store'
import 'lib-flexible/flexible'
import '@/icons'
import '@/fonts/fonts.css'
import './utils/vant'
import './utils/appback.js' 
// 复制插件
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);
// 二维码插件
import VueQriously from 'vue-qriously';
Vue.use(VueQriously); 

import moreDialog from './utils/modularization';
Vue.use(moreDialog);

import loading from './components/loading-custom/loading-ui.vue'
import navigation from './components/navigation-bar.vue'
Vue.component('loadingUi', loading)
Vue.component('navigation', navigation)

import global_msg from './utils/global'
import { languageList } from '@/utils/language/languages';
// 自定义loading加载js文件
import { loadingNode, loadingClear } from '@/components/loading-custom/loading-js'
Vue.prototype.$language = languageList
Vue.prototype.$loadingNode = loadingNode
Vue.prototype.$loadingClear = loadingClear
Vue.prototype.$globalCommon = global_msg;
Vue.prototype.$JumpShops = global_msg.$JumpShops;
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')