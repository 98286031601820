// 葡萄牙语
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语    
    'Português', // 葡萄牙语 
    '繁體中文', // 繁体中文
  ],
  closePopup: {
    popupTitle: 'Tem a certeza que pretende cancelar o pagamento?',
    contractTitle: 'Se você cancelar este processo de pagamento, todo o progresso será perdido.',
    affirmBtn: 'Sim, cancelar o processo de pagamento',
    cancelBtn: 'Não, continue com o pagamento'
  },
  'chain': {
    navTitle: '',
    bodyTitle: 'escolha & Rede de pagamentos',
    messageTitle: 'Escolher a rede errada (blockchain) resultará em perdas financeiras',
    priceTitle: 'Qualquer quantidade',
    btnTitle: 'continuar'
  },
  'payOrder': {
    navTitle: '',
    bodyTitle: 'Pague o seu pedido',
    copy: 'cópia',
    countTitle: 'Quantidade',
    address: 'endereço',
    network: 'Rede seleccionada:',
    successCopy: 'A cópia foi bem sucedida'
  },
  'payResult': {
    navTitle: '',
    backOrderTitle: 'A encomenda expirou',
    backOrderMessage: 'Nenhum pagamento foi encontrado dentro do tempo especificado. Para pagar o seu pedido, reinicie o processo de pagamento.',

    orderCancelTitle: 'Cancelamento da encomenda',

    orderSuccessTitle: 'Ordem concluída!',
    orderSuccessResultTitle: 'O comerciante recebeu o pagamento',
    orderSuccessMessage: 'Chegou com sucesso à conta do comerciante.',

    returnBtn: 'Voltar à página do comerciante',

  },

  // 公共提示
  messageLanguage: {
    loadingTitle: "Carregamento", // 正在加載
    finishedText: "nada", //無
    loadingText: "Carregamento", // 正在加載
    nullText: 'Actualmente, não existem dados disponíveis',
    errorPopupTitle: 'prompt',
    errorPopupAffirm: 'confirmar',
    errorPopupClose: 'cancelar',
  },

};
export default obj;
