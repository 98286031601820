import service from '../required/axios'
export default {
  // 获取订单详情 
  getOrderDetail (data) {
    return service({
      url: '/info',
      method: "get",
    })
  },
  getOpen_page (data) {
    return service({
      url: '/open_page',
      method: "get",
    })
  },
  cancelOrderPort (data) {
    return service({
      url: '/cancel',
      methods: 'post'
    })
  }
}