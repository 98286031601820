<template>
  <!-- 头部导航栏 -->
  <div class="navigation-bar-warper">
    <div class="nav-bar-boxers">
      <div class="nav-left">
        <span>{{ title }}</span>
      </div>
      <div class="nav-right">
        <div class="nav-setLanguage-boxers">
          <div
            :class="[
              'set-language',
              setLanguageIsShow && 'active-language-set',
            ]"
          >
            <img
              :src="setLanguageObj.languageIcon"
              class="set-img"
              @click="selectLanguage"
            />
            <div class="set-language-select" v-if="setLanguageIsShow">
              <div
                class="set-language-item"
                v-for="(item, index) in setLanguageList"
                :key="index"
                @click="onChangeLanguage(item)"
              >
                <div class="set-item-boxers">
                  <svg-icon
                    :icon-class="item.pigeonIcon"
                    width="24px"
                    height="24px"
                    color="rgb(113, 93, 221)"
                  ></svg-icon>
                  <img :src="item.languageIcon" alt="" class="select-img" />
                  <p>{{ item.label }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="svg-icon-class">
          <div class="svg-icon-images" v-if="!closeIsShow">
            <svg-icon icon-class="close" @click="onClickClose"></svg-icon>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      class="van-popup-warper"
      v-model="visibleIsShow"
      position="center"
      round
      closeable
      :close-on-click-overlay="false"
      @close="visibleIsShow = false"
    >
      <div class="popup-body-warper">
        <div class="popup-title">{{ popupLangue.popupTitle }}</div>
        <div class="popup-body-contract">
          {{ popupLangue.contractTitle }}
        </div>
        <div class="popup-btn-boxers">
          <div class="btn-info button" @click="handlerClose">
            {{ popupLangue.affirmBtn }}
          </div>
          <div class="btn-primary button" @click="handlerAffirm">
            {{ popupLangue.cancelBtn }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import payIndexApi from "@/api/payIndex";
export default {
  name: "navigationIndex",
  props: {
    title: {
      type: String,
      default: () => {
        return "Nordvpn S.A";
      },
    },
  },
  data() {
    return {
      language: this.$language("languageVersion"),
      popupLangue: this.$language("closePopup"),
      setLanguageList: [],
      setLanguageIsShow: false,
      setLanguageObj: {
        languageIcon: "",
      },
      activeIndex: null,
      visibleIsShow: false,
      closeIsShow: false,
    };
  },
  watch: {
    $route(newVal) {
      this.closeIsShow = false;
      if (newVal.path === "/payResult") {
        this.closeIsShow = true;
      }
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.closeIsShow = false;
      if (this.$route.path === "/payResult") {
        this.closeIsShow = true;
      }
      this.setLanguageList = [];
      this.language.map((item) => {
        this.setLanguageList.push({
          label: item,
          value: item,
          pigeonIcon: "",
        });
      });
      this.setLanguageList.map((item, index) => {
        item.languageIcon = require(`../assets/languageIcon/${index + 1}.png`);
      });
      this.languageCallBack();
    },
    onClickClose() {
      this.setLanguageIsShow = false;
      this.visibleIsShow = true;
    },
    handlerClose() {
      payIndexApi.cancelOrderPort().then(({ code, data }) => {
        if (code == 200) {
          this.setLanguageIsShow = false;
          let authorizationPay = Object.keys(this.$route.query)[0];
          this.$router.push({ path: `/payResult?${authorizationPay}` });
        }
      });
      this.visibleIsShow = false;
    },
    handlerAffirm() {
      this.setLanguageIsShow = false;
      this.visibleIsShow = false;
    },
    onChangeLanguage (record) { 
      localStorage.setItem("languageType", record.value);
      this.$store.commit("languageTypeFn", record.value);
      setTimeout(() => {
        this.setLanguageIsShow = false;
      }, 200);

      this.language = this.$language("languageVersion");
      this.popupLangue = this.$language("closePopup");
      this.onLoad();
      this.$forceUpdate();
      this.$emit("setLanguage");
    },
    selectLanguage() {
      this.setLanguageIsShow = !this.setLanguageIsShow;
    },
    languageCallBack() {
      let languageType = localStorage.getItem("languageType") || "English";
      this.setLanguageList.map((item) => {
        item.pigeonIcon = "";
        if (item.value === languageType) {
          item.pigeonIcon = "pigeon";
          this.setLanguageObj = {
            languageIcon: item.languageIcon,
            language: item.label,
          };
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-bar-warper {
  width: 100%;
  height: 160px;
  font-family: "Inter, Roboto, Arial, sans-serif";
  .nav-bar-boxers {
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    .nav-left {
      display: flex;
      align-items: center;
      width: 35%;
      height: 100%;
      font-size: 38px;
      color: #000000;
      font-weight: 600;
    }
    .nav-right {
      width: 65%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .nav-setLanguage-boxers {
        position: relative;
        .set-language {
          padding: 16px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          background-color: #fff;
          border-radius: 26px;
          .set-img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            cursor: pointer;
          }
        }
        .set-language-select {
          min-width: 360px;
          position: absolute;
          top: 120px;
          right: 40px;
          background: #fff;
          border-radius: 16px;
          padding: 10px 0;
          box-shadow: 0 0 15px 0 #dbdbdb;
          z-index: 9;
          .set-language-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            padding: 25px;
            .set-item-boxers {
              width: 100%;
              display: flex;
              align-items: center;
              svg {
                margin-right: 12px;
              }
              .select-img {
                width: 42px;
                height: 48px;
                margin-right: 20px;
              }
              p {
                width: 85%;
                color: #212020;
                font-size: 32px;
              }
            }
          }
        }
        .active-language-set {
          background-color: #eeeef0;
        }
      }
      .svg-icon-class {
        margin-left: 26px;
        height: 100%;
        display: flex;
        align-items: center;

        .svg-icon-images {
          border-radius: 16px;
          padding: 28px 18px;
          background: #fff;
          .svg-icon {
            width: 32px !important;
            height: 32px !important;
          }
        }
      }
    }
  }
  .van-popup-warper {
    width: 668px;
    padding: 40px;
    background-color: #fff;
    .popup-body-warper {
      .popup-title {
        font-size: 32px;
        line-height: 50px;
        color: rgb(33, 32, 32);
        font-weight: 600;
        white-space: break-spaces;
        word-break: break-all;
        padding-right: 48px;
      }
      .popup-body-contract {
        font-size: 28px;
        color: rgb(119, 127, 137);
        margin: 32px 0 48px 0;
        white-space: break-spaces;
        word-break: break-all;
      }
      .popup-btn-boxers {
        width: 100%;
        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 32px;
          width: 100%;
          border-radius: 16px;
          background-color: rgb(227, 227, 227);
          color: #000000;
          margin-bottom: 15px;
          font-size: 32px;
          font-weight: 600;
          line-height: 65px;
        }
        .btn-primary {
          background-color: #725dde;
          color: #fff;
        }
      }
    }
  }
}
</style>
<style>
.van-icon-cross:before {
  font-size: 36px;
  color: #212020;
}
</style>
