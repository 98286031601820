<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  mounted() {},
  methods: {},
};
</script>

<style>
#app {
  width: 100%;
  height: 100vh;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
</style>
