let hostConfig = window.location.hostname
const { baseUrl, url } = getHostConfig()
console.log(url);
// 语言转换
const lang = {
  null: "english",
  English: "english",
  Português: "portuguese",
  繁體中文: "chineseTraditional",
}

/*******************************************************************/

// 获取域名请求配置
function getHostConfig () { 
  return require(`@/utils/hostConfig/default`).hostConfigObj
} 
export default {
  url,
  baseUrl,
  lang,
}
