import '@/components/css/errorPage.css'
import { languageList } from '@/utils/language/languages';
import Vue from 'vue'
import axios from 'axios'
import baseUrl from '@/utils/projectConfig'
const ServerRequestUrl = baseUrl.baseUrl
import { Dialog } from 'vant';
import { Toast } from 'vant'
let messageLoading
Vue.nextTick(() => {
  messageLoading = languageList('messageLanguage')
})
let requestList = new Set() // 存储请求url
// 创建axios实例
const service = axios.create({
  baseURL: ServerRequestUrl,
  timeout: 5000 // 请求超时时间
})
// request拦截器==>对请求参数做处理
service.interceptors.request.use(
  // // 利用cancelToken 取消当次请求 
  config => {
    //   config.cancelToken = new axios.CancelToken(e => {
    //     // 在这里阻止重复请求，上个请求未完成时，相同的请求不会再次执行
    //     requestList.has(config.url) ? e(`${location.host}${config.url}---重复请求被中断`) : requestList.add(config.url)
    //   })
    // config.headers.authorization = window.localStorage.getItem('authorizationPay');
    config.headers.authorization = window.location.href.split('?')[1]
    config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]

    return config
  },
  error => { // 请求错误处理
    Promise.reject(error)
  })
// respone拦截器==>对响应做处理
service.interceptors.response.use(
  // 相同请求不得在600毫秒内重复发送，反之继续执行
  // 成功请求到数据
  response => {
    // setTimeout(() => {
    //   requestList.delete(response.config.url)
    // }, 300) 
    if (response.data.code !== 200) {
      let paramsData = {
        url: response.config.url,
        method: response.config.method,
        data: response.config.data,
        params: response.config.data,
        message: response.data.message
      }
      dialogDoM(paramsData)
      // Toast.fail({
      //   duration: 3000,
      //   message: response.data.message,
      //   forbidClick: true,
      // })

    } else if (response.data.code === 200) { }
    return response.data
  },
  error => {
    Toast.clear()
    let text = JSON.parse(JSON.stringify(error))
    if (text.url) {
      requestList.delete(error.config.url)
      let paramsData = {
        url: text.config.url,
        method: text.config.method,
        data: text.config.data,
        params: text.config.data,
        message: text.message
      }
      dialogDoM(paramsData)
    }
    return Promise.reject(error)
  }
)
function dialogDoM (params) {
  // console.log(params);
  Dialog.alert({
    title: messageLoading.errorPopupTitle,
    message: params.message,
    className: 'error-popup',
    confirmButtonText: messageLoading.errorPopupAffirm,
    // cancelButtonText: messageLoading.errorPopupClose,
    overlay: true,
    lockScroll: true,
    // beforeClose: (action, done) => {
    //   done();
    // },
  }).then(() => {
    errorPort(params)
  })
}
function errorPort (config) {
  return service({
    url: config.url,
    method: config.method,
    data: config.data,
    params: confirm.data
  })
}
export default service