// 繁体中文

let obj = {};
obj = {
  // 语言
  // 语言
  languageVersion: [
    'English',  // 英语    
    'Português', // 葡萄牙语 
    '繁體中文', // 繁体中文
  ],
  closePopup: {
    popupTitle: 'Are you sure you want to cancel the payment?',
    contractTitle: 'If you cancel this payment process, all progress will be lost.',
    affirmBtn: 'Yes, cancel the payment process',
    cancelBtn: 'No, continue with payment'
  },
  'chain': {
    navTitle: '',
    bodyTitle: 'choice & Payment network',
    messageTitle: 'Choosing the wrong network (blockchain) will result in financial losses',
    priceTitle: 'Any quantity',
    btnTitle: 'continue'
  },
  'payOrder': {
    navTitle: '',
    bodyTitle: 'Pay for your order',
    copy: 'copy',
    countTitle: 'quantity',
    address: 'address',
    network: 'Selected network:',
    successCopy: 'Copy successful'
  },
  'payResult': {
    navTitle: '',
    backOrderTitle: 'Order has expired',
    backOrderMessage: 'No payment was found within the specified time. To pay for your order, please restart the payment process.',

    orderCancelTitle: 'Order cancellation',

    orderSuccessTitle: 'Order completed!',
    orderSuccessResultTitle: 'Merchant has received payment',
    orderSuccessMessage: 'Successfully reached the merchant account.',

    returnBtn: 'Return to merchant page',

  },

  // 公共提示
  messageLanguage: {
    loadingTitle: "Loading", // 正在加載
    finishedText: "nothing", //無
    loadingText: "Loading", // 正在加載
    nullText: 'There is currently no data available',
    errorPopupTitle: 'prompt',
    errorPopupAffirm: 'confirm',
    errorPopupClose: 'cancellation',
  },

};
export default obj;
