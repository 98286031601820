import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const routes = [
  {
    path: "/",
    name: "pageIndex",
    redirect: "/pageIndex",
  },
  {
    path: "/pageIndex",
    name: "pageIndex",
    component: () => import("@/views/pageIndex.vue"), 
    children: [
      {
        path: "/chain",
        name: "chainIndex",
        component: () => import("@/views/page/chainIndex.vue"),
      },
      {
        path: "/payOrder",
        name: "payOrderIndex",
        component: () => import("@/views/page/pay-order.vue"),
        meta: {
          title: '支付订单'
        }
      },
      {
        path: "/payResult",
        name: "payResultIndex",
        component: () => import("@/views/page/pay-result.vue"),
      },
    ]
  },

];
const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  console.log(to, from);
  next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router