import english from './languageModule/english'
import chineseTraditional from './languageModule/chineseTraditional'
import portuguese from './languageModule/portuguese'
function languageType (key) {
  if (key == 'English' || key == 'english' || !key) {
    return english
  }
  if (key == 'Português') {
    return portuguese
  }
  if (key == '繁體中文') {
    return chineseTraditional
  }

}

function languageList (key) {
  let languageObj = {}
  let language = localStorage.getItem("languageType")
  languageObj = languageType(language)
  if (key) {
    return languageObj[key]
  }
  let UrlPath = window.location.hash.slice(2)
  if (UrlPath.includes("?")) {
    UrlPath = UrlPath.split("?")[0]
  }
  return languageObj[UrlPath]
}

function settingLanguage (type, key) {
  let languageObj = languageType(type);
  if (key) {
    return languageObj[key]
  }
  let UrlPath = window.location.hash.slice(2) || 'home'
  if (UrlPath.includes("?")) {
    UrlPath = UrlPath.split("?")[0]
  }
  return languageObj[UrlPath]
}
export { languageList, languageType, settingLanguage }