// 繁体中文

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语    
    'Português', // 葡萄牙语 
    '繁體中文', // 繁体中文
  ],
  closePopup: {
    popupTitle: '您確定要取消付款嗎？',
    contractTitle: '如果您取消此付款流程，所有進度都會遺失。',
    affirmBtn: '是的，取消付款流程',
    cancelBtn: '否，繼續付款'
  },
  'chain': {
    navTitle: '',
    bodyTitle: '選擇&的支付網絡',
    messageTitle: '選擇錯誤的網路（區塊鏈）將導致資金損失',
    priceTitle: '任意數量',
    btnTitle: '繼續'
  },
  'payOrder': {
    navTitle: '',
    bodyTitle: '支付您的訂單',
    copy: '複製',
    countTitle: '數量',
    address: '地址',
    network: '所選網路：',
    successCopy: '複製成功'
  },
  'payResult': {
    navTitle: '',
    backOrderTitle: '訂單已過期',
    backOrderMessage: '在規定的時間內沒有發現付款。 若要支付您的訂單，請重新啟動付款流程。',

    orderCancelTitle: '訂單取消',

    orderSuccessTitle: '訂單完成!',
    orderSuccessResultTitle: '商家已收款',
    orderSuccessMessage: '已成功到達商家帳戶。',

    returnBtn: '返回商家頁面',

  },

  // 公共提示
  messageLanguage: {
    loadingTitle: "加載中", // 正在加載
    finishedText: "無", //無
    loadingText: "加載中", // 正在加載
    nullText: '暫無數據',
    errorPopupTitle: '提示',
    errorPopupAffirm: '确认',
    errorPopupClose: '取消',
  },

};
export default obj;
