import { render, staticRenderFns } from "./loading-ui.vue?vue&type=template&id=921f7af4&scoped=true"
import script from "./loading-ui.vue?vue&type=script&lang=js"
export * from "./loading-ui.vue?vue&type=script&lang=js"
import style0 from "./loading-ui.vue?vue&type=style&index=0&id=921f7af4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "921f7af4",
  null
  
)

export default component.exports